<template>
  <div class="contact">
    <!--====== PAGE TITLE PART START ======-->

    <div class="page-title-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-title-item text-center">
              <h2 class="title">Contact Us</h2>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/">kibbutztech</router-link>
                  </li>
                </ol>
              </nav>
            </div>
            <!-- page title -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>

    <!--====== PAGE TITLE PART ENDS ======-->

    <!--====== CONTACT DETAILS PART START ======-->

    <div class="contact-details-area pt-90 pb-120">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="contact-info mr-30 mt-30">
              <div class="contact-item-1">
                <div class="contact-info-item text-center">
                  <i class="fal fa-phone"></i>
                  <h5 class="title">Phone Number</h5>
                  <p>
                    <a href="tel:'`$+9197269 80051`">+91 97269 80051</a>
                  </p>
                </div>
                <div class="contact-info-item text-center">
                  <i class="fal fa-envelope"></i>
                  <h5 class="title">Email Address</h5>
                  <P>
                    <a target="_blank" href="mailto:info@kibbutztech.com">info@kibbutztech.com</a>
                  </P>
                </div>
              </div>
              <div class="contact-item-1">
                <div class="contact-info-item text-center">
                  <i class="fal fa-map"></i>
                  <h5 class="title">Office Location</h5>
                  <p>
                    Kibbutz techs,
                    Office No – 409A, The Spire,
                    Near Sheetal Park, 150 Feet Ring Rd,
                    Rajkot – 360005
                  </p>
                </div>
                <div class="contact-info-item text-center">
                  <i class="fal fa-globe"></i>
                  <h5 class="title">Social Network</h5>
                  <p>fb.com/example</p>
                </div>
              </div>
            </div>
            <!-- contact info -->
          </div>
          <div class="col-lg-6">
            <div class="map-area mt-30">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.9191595626594!2d70.76303802695313!3d22.318896900000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959c99fab3b176d%3A0x3b0bd6688769a252!2sThe%20Spire!5e0!3m2!1sen!2sin!4v1655482244046!5m2!1sen!2sin"
                width="600"
                height="450"
                style="border:0;"
                allowfullscreen
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <!-- map area -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>

    <!--====== CONTACT DETAILS PART ENDS ======-->

    <!--====== GET IN TOUCH PART START ======-->

    <div class="get-in-touch-area get-in-touch-area-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="section-title text-left">
              <span>Get In Touch</span>
              <h2 class="title">Estimate For Your Projects.</h2>
            </div>
            <!-- section title -->
            <div class="form-area">
              <!-- <form > -->
              <div class="input-box mt-45">
                <input type="text" name="name" placeholder="Enter your name" id="name" required />
                <i class="fal fa-user"></i>
              </div>
              <div class="input-box mt-20">
                <input type="email" name="email" placeholder="Enter your email" id="email" required />
                <i class="fal fa-envelope"></i>
              </div>
              <div class="input-box mt-20">
                <textarea
                  id="message"
                  name="message"
                  cols="30"
                  rows="10"
                  placeholder="Enter your message"
                  required
                ></textarea>
                <i class="fal fa-edit"></i>
                <button onclick="sendEmail()" class="main-btn" type="submit">Submit Now</button>
              </div>
              <!-- </form> -->
              <p class="form-message"></p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="d-lg-block mt-40">
              <img src="assets/images/mobile6.png" alt="image" />
            </div>
            <!-- about thumb -->
          </div>
        </div>
      </div>
    </div>

    <!--====== GET IN TOUCH PART ENDS ======-->
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() {
    // console.log("this.$route.params",this.$route.params.name)
    window.scrollTo(0, 0);
  }
};
</script>
 